import React, { useState, useContext } from 'react';
import {
    Button,
    DataTable,
    DataTableHead,
    DataTableRow,
    Heading,
    Popup,
    PopupSection,
} from '@flixbus/honeycomb-react';
import {
    Icon,
    IconCheckmarkStrong,
    IconCrossed,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../system/Translate';

const useWhatsAppTemplateSentPopup = (orderIds) => {
    const { translate } = useContext(TranslateContext);
    const [isActive, setIsActive] = useState(false);
    const [processedOrderIds, setProcessedOrderIds] = useState(null);

    const openPopup = (successOrderIds) => {
        setProcessedOrderIds(
            orderIds.map((item) => {
                const wasSent = successOrderIds.some((successOrderIdString) => successOrderIdString.includes(item));
                return { orderId: item, wasSent };
            })
        )
        setIsActive(true);
    };

    const closePopup = () => {
        setIsActive(false);
    };

    const WhatsAppTemplateSentPopup = () => (
        <Popup active={isActive} onOverlayClick={closePopup}>
            <PopupSection type="title">
                <Heading size={2} sectionHeader>
                    {translate('whatsapp_template_delivery_status')}
                </Heading>
            </PopupSection>
            <PopupSection type="content">
                {translate('whatsapp_sent_popup_description')}
            </PopupSection>
            <PopupSection>
                <DataTable
                    headers={(
                        <DataTableHead>
                            <th>{translate('order_id')}</th>
                            <th>{translate('was_sent')}</th>
                        </DataTableHead>
                      )}
                >
                    {Array.isArray(processedOrderIds) && processedOrderIds.map(({ orderId, wasSent }) => (
                        <DataTableRow key={orderId}>
                            <th>{orderId}</th>
                            <td>
                                <Icon
                                    size={5}
                                    extraClasses={wasSent ? 'cn-whatsapp-status__success' : 'cn-whatsapp-status__fail'}
                                    InlineIcon={wasSent ? IconCheckmarkStrong : IconCrossed}
                                />
                            </td>
                        </DataTableRow>
                    ))}
                </DataTable>
            </PopupSection>
            <PopupSection type="actions">
                <Button appearance="primary" onClick={() => closePopup()}>{translate('ok')}</Button>
            </PopupSection>
        </Popup>
    );

    return {
        openPopup,
        closePopup,
        WhatsAppTemplateSentPopup,
    };
};

export default useWhatsAppTemplateSentPopup;